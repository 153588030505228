
.modal {
    position: fixed;
    z-index: 1; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%;
    background-image: url(./media/ocean-bg.webp);
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .modal-content {
    position: relative;
    text-align: center;
    margin: 5% auto;
    padding: 20px;
    border: 1px solid #888;
    border-radius: 5px;
    height: 70%;
    width: 80%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    animation-name: animatetop;
    animation-duration: 0.4s
  }

  .modal-main {
    width: 100%;
    height: 100%;
    color: #fff;
    background: url(./media/WhatsApp\ Image\ 2023-04-27\ at\ 19.53.28.jpg);
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }
  
  @keyframes animatetop {
  from {top: -300px; opacity: 0}
  to {top: 0; opacity: 1}
}