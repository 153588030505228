.main-body{
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 450px;
    padding: 0px 12% 0px 12%;
    box-sizing: border-box;
    background-color: #031530;
    color: #fff;
}
.tempat-contact {
    display: flex;
    flex-direction: column;
    width: 280px;
}
.tempat-contact p{
    line-height: 24px;
}