.navbar {
    position: fixed;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 60px;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;
    background-color: none;
    top: 20px;
    z-index: 1;
    -webkit-transition: background-color ease-in 0.2s, top ease-in 0.2s;
    -moz-transition: background-color ease-in 0.2s, top ease-in 0.2s;
    -o-transition: background-color ease-in 0.2s, top ease-in 0.2s;
    transition: background-color ease-in 0.2s, top ease-in 0.2s;
}
.navbar.scrolled {
    background-color: #000;
    top: 0px;
}

.logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #fff;
    flex-direction: row;
    margin: 0px 0px 0px 30px;
    background-image: url(./media/Logo\ Navbar.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 197px;
    height: 60px;
    
}
.button-menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    margin: 0px 30px 0px 0px;
}

.button-menu li {
    list-style: none;
    justify-content: center;
    align-items: center;  
    
}

.button-menu a {
    display: flex;
    width: 120px;
    height: 45px;
    text-decoration: none;
    background-color: none;
    color: #fff;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.button-menu a.last-child  {
    display: flex;
    width: 120px;
    height: 45px;
    text-decoration: none;
    background-color: aqua;
    color: #00f;
    font-weight: 700;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    margin-left: 2px;
}

.button-menu a:hover {
    background-color: aqua;
    color: black;
}

.button-menu a:active {
    background-color: aqua;
}




