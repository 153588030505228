.main-page {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    width: 100%;
    height: fit-content;
    background-color: #fff;
}

.main-background {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    padding-top: 60px;
    padding-left: 60px;
    background-image: url(../media/3.\ learn\ biodiversity.JPG);
    background-size: cover;
    background-position: center center;
    
}

.main-background h1 {
    color: white;
    border-color: aqua;
    border: 0.5px;
    padding: 0;
    margin: 0;
    font-size: 42px;
}

.main-background p {
    color: white;
    padding: 0;
    margin: 0;
}
.main-background button{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 45px;
    margin-top: 45px;
    border-radius: 20px;
    color: #00f;
    background-color: #fff;
    font-weight: bolder;
    cursor: pointer;
}

#content-two {
    display: flex;
    position: relative;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
}

.content-of-content-two {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 76%;
    height: 80%;
    border-radius: 25px;
    background-color: #2f2c4f;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0px 30px 0px 80px;
}

.gambar-content-two{
    display: block;
    flex-direction: column;
    position: relative;
    width: 400px;
    height: 350px;
    background-image: url(../media/4.\ Diving\ in\ Nature.JPG);
    background-position: center center;
    background-size: cover;
}
.deskripsi-gambar {
    display: flex;
    flex-direction: column;
    position: relative;
    height: fit-content;
    
}

.content-of-content-two h2 {
    color: #fff;
    margin: 0px 0px 10px 0px;
    padding: 5px 0px 0px 0px;
    box-sizing: border-box;
}

.content-of-content-two p{
    color: #fff;
    margin: 0px 0px 10px 0px;
    padding: 5px 0px 0px 0px;
    box-sizing: border-box;
    font-size: 18px;
}

.get-started{
    display: flex;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    width: 100px;
    height: 45px;
    border-radius: 20px;
    font-weight: 500;
    margin-top: 40px;
}

.content-lanjutan {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    text-align: center;
    color: #2f2c4f;
    margin: 0px 0px 20px 0px;
}
.content-lanjutan h2 {
    font-size: 36px;
}
.content-lanjutan p {
    font-size: 24px;
}

.content-visi {
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    align-items: center;
    width: 76%;
    height: fit-content;
    margin-bottom: 48px;
}

.kartu-content-visi {
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 400px;
    background-color: #f2f4fa;
    border-radius: 30px;
    box-sizing: border-box;
    margin: 0px 10px 0px 10px;
    padding: 0px 0px 0px 2%;
    transition: ease-in-out 0.2s;
    line-height: 24px;
}
.kartu-content-visi img{
    margin-left: 60px;
}
.kartu-content-visi a {
    display: flex;
    text-decoration: none;
    font-weight: bolder;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 40px;
    border-radius: 90px;
    background-color: blue;
    color: #fff;
}

.kartu-content-visi-blue {
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 400px;
    background-color: #4476b0;
    color: #fff;
    border-radius: 30px;
    box-sizing: border-box;
    margin: 0px 5px 0px 5px;
    padding: 0px 24px 0px 24px;
    transition: linear 0.2s;
}


.isi-kartu-content-visi-blue,
.isi-kartu-content-visi-blue-2{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    width: fit-content;
    margin: 0px 0px 30px 0px;
    box-sizing: border-box;
    padding: 0px 0px 0px 14px;
    border-left: 1px solid #000;
}
.isi-kartu-content-visi-blue {
    line-height: 18px;
    font-weight: 700;
}
.isi-kartu-content-visi-blue-2 {
    line-height: 21px;
}


.kartu-content-visi:hover,
.kartu-content-visi-blue:hover{
    box-shadow: 2px 2px 3px 2px grey;
}

.content-value{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 420px;
    background-image: url(../media/1.\ diving\ with\ net\ zero\ in\ mind.JPG);
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 80px;
}
.content-value h2 {
    font-size: 28px;
    font-weight: 600;
    color: #fff;
}

.content-value-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.kartu-content-value {
    display: flex;
    width: 270px;
    height: 240px;
    background-color: #fff;
    border-radius: 15px;
    margin: 0px 15px 0px 15px; 
    box-shadow: 1px 1px 5px 1px #000;
    
}

.isi-kartu-content-value {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #000;
}


.content-services{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: fit-content;
    padding: 0px 0px 12px 0px;
    box-sizing: border-box;
}

.content-services h2{
    color: #07295a;
    font-size: 36px;
    font-weight: bold;
    line-height: 0px;
}

.content-services p {
    color: #717c90;
    line-height: 18px;
}

.kolom-service,
.kolom-service-2 {
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    align-items: center;
    padding: 0px 12% 0px 12%;
    box-sizing: border-box;
    width: 100%;
    height: 260px;
}

.card-kolom-service,
.card-kolom-service-2 {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: flex-start;
    height: 240px;
    border-radius: 20px 0px 20px 0px ;
    box-shadow: 1px 1px 2px 1px #eee;
    color: #07295a;
    cursor: pointer;
    border: solid #ddd 0.5px ;
    transition: box-shadow ease-in-out 0.2s;
    padding: 0px 0px 0px 20px;
    box-sizing: border-box;
    text-align: left;
}
.card-kolom-service{
    width: 32%;
}
.card-kolom-service-2 {
    width: 49%;
}

.card-kolom-service:hover, 
.card-kolom-service-2:hover {
    box-shadow: 2px 2px 4px 3px #947575;
    background-color: #1e70ea;
    color: #fff;
    border: none;
}

.card-kolom-service p,
.card-kolom-service-2 p {
    color: inherit;
}

