.halaman-subservices {
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    align-items: center;
    position: relative;
    width: 100%;
    min-height: 100vh;
    margin-top: 80px;
    padding-top: 60px;
    box-sizing: border-box;
    background-color: #fff;
}
.container {
    display: block;
    flex-direction: column;
    align-items: center;
    width: 50%;
    border: solid #6D6C84 0.5px;
    height: fit-content;
    margin-bottom: 20px;
    transition: height 0.3s ease-in-out;
    overflow: hidden;
}


.button-collapse {
    display: flex;
    justify-content: center;
    background-color: white;
    font-weight: 300;
    color: #6D6C84;
    align-items: center;
    height: 40px;
    width: 100%;

}

.content {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-height: 400px;
    align-items: center;
    justify-content: space-evenly;
    transition: max-height 1s ease-in-out;
    -webkit-transition: max-height 0.2s ease-in;
    -moz-transition: max-height 0.2s ease-in;
    -o-transition: max-height 0.2s ease-in;
    overflow: hidden;
}
.content.close {
    max-height: 0px;
}

