.halaman-utama {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
    padding-top: 60px;
    text-align: center;
    background-color: #fff;
    margin: 80px 0px 0px 0px;
}